/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onRouteUpdate = ({ location, prevLocation }) => {
  //console.log("new pathname", location.pathname)
  //console.log("old pathname", prevLocation ? prevLocation.pathname : null)
  if (window.matumo_a) {
    window.matumo_a()
    console.log("loaded.")
  } else {
    console.log("loading...")
  }
}
